<template>
  <div class="coupon">
    <van-tabs v-model="active" background="#f8f8f8">
      <van-tab title="全部"></van-tab>
      <van-tab title="满减券"></van-tab>
      <van-tab title="商品券"></van-tab>
      <van-tab title="会员券"></van-tab>
      <van-tab title="运费券"></van-tab>
    </van-tabs>
    <ul class="tab_childer">
      <li class="act">最近新到</li>
      <li>即将过期</li>
    </ul>

    <oList></oList>

    <div class="coupon_fixed">
      <ul>
        <li
          class="reload"
          @click="
            $router.push({
              name: 'CouponReload',
            })
          "
        >
          优惠券使用记录
        </li>
        <li class="solid"></li>

        <li class="getCoupon" @click="show = true">
          兑换优惠券
        </li>
      </ul>
    </div>

    <van-dialog v-model="show" title="兑换优惠券" show-cancel-button>
      <van-field
        style="padding: 20px"
        v-model="text"
        placeholder="请输入券码"
        clearable
        autofocus
      />
    </van-dialog>
  </div>
</template>

<script>
import { Tab, Tabs, Dialog, Field } from "vant";
import oList from "./components/list.vue";
export default {
  components: {
    vanTab: Tab,
    vanTabs: Tabs,
    [Dialog.Component.name]: Dialog.Component,
    vanField: Field,
    oList,
  },
  data() {
    return {
      active: 2,
      show: false,
      text: "",
    };
  },
};
</script>
<style lang="scss">
.coupon {
  background: #f8f8f8;
  width: 100%;
}
body,
html,
#app {
  height: 100%;
  width: 100%;
}
</style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

::v-deep {
  .van-tabs__wrap {
    display: block;
  }
  .van-tabs__line {
    width: rem(36);
    bottom: 18px;
  }
}

.tab_childer {
  margin-top: rem(28);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: rem(40);
  li {
    flex: 1;
    text-align: center;
    font-size: rem(28);
    color: #666666;
  }
  .act {
    color: #ff4848;
  }
}

.coupon_fixed {
  position: fixed;
  width: 100%;
  height: rem(98);
  background: #ffffff;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  ul {
    display: flex;
    width: 100%;
    height: rem(98);
    align-items: center;
    justify-content: center;
  }

  .reload,
  .getCoupon {
    flex: 1;
    color: #222222;
    font-size: rem(28);
    text-align: center;
  }
  .solid {
    width: rem(2) !important;
    height: rem(40);
    background: #d3d3d3;
  }
  .getCoupon {
  }
}
</style>
